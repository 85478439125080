import React from "react";
import { View, Text, Image } from "react-native-web";

export default function Home() {
  return (
    <View style={styles.container}>
      <View style={styles.page}>
        <View style={styles.banner}>
          <View style={{ width: "15%" }}>
            <Image
              source={require("../assets/red5.png")}
              style={{
                width: 100,
                height: 100,
                backgroundColor: "#123",
                marginLeft: 75,
              }}
            />
          </View>
          <View
            style={{
              width: "70%",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <Text style={styles.header}>STRIPE 5 - </Text>

            <Text style={[styles.subHeader, { marginTop: 20 }]}>
              We Bring More!
            </Text>
          </View>
        </View>

        <View
          style={{
            width: "100%",
            alignItems: "flex-start",
            flexDirection: "row",
          }}
        >
          <View style={styles.infoView}>
            <Text style={styles.infoTitle}>Who are we?</Text>
            <Text>
              We are a group of BJJ practitioners who are passionate about the
              sport and want to help others find a club to join. We hope we can
              bring as many clubs together to grow the sport and collaborate to
              create a community from which events, competitions, instructionals
              and discussions can thrive.
            </Text>
          </View>

          <View style={styles.infoView}>
            <Text style={styles.infoTitle}>What do we do?</Text>
            <Text>
              We provide a Gym Management System we lovingly refer to as
              MatSpace. This system has two parts consisting of a mobile
              application for both iOS and Android devices, which can also be
              run on tablets, and a web portal for gym owners / admins from
              which accounts and timetables can be managed along with various
              analytics regarding attendance and more. In addition we have a
              built in subscriptions service that allows Gym owners to manage
              their members and their payments from within one all encompassing
              system preventing the need for seperate timetabling / sign ups
              applications and payments systems.
            </Text>
            <Text>
              In addition we have a built in subscriptions service that allows
              Gym owners to manage their members and their payments from within
              one all encompassing system preventing the need for seperate
              timetabling / sign ups applications and payments systems.
            </Text>
          </View>

          <View style={styles.infoView}>
            <Text style={styles.infoTitle}>Why Us?</Text>
            <Text>
              While there are countless other gyms who have developed their own
              mobile apps and a large number of OTB gym management applications.
              We are the first who are looking to create a singular, low cost
              solution that combines all of the features and functions that a
              gym owner would need to manage their gym and their members. We are
              also the first to offer a free service to the end user, the
              student, who can use the app to find a gym and sign up to it
              without any cost to them. We are also the first to offer a full
              payments / subscription service to prevent the gym owners having
              to juggle multiple systems to make sure the students in the class
              have paid to be there.
            </Text>
          </View>
        </View>
        <View
          style={{
            width: "75%",
            alignItems: "center",
            borderTopWidth: 2,
            borderColor: "#a11",
          }}
        >
          <View style={{ alignItems: "center", alignSelf: "center" }}>
            <Image
              style={{
                alignItems: "center",
                marginTop: 15,
                width: 150,
                height: 150,
                borderRadius: 75,
              }}
              source={require("../assets/applogo.png")}
            />
            <Text>
              Currently in use and in developement at Carlson Gracie Kent!
            </Text>
          </View>
        </View>
      </View>
    </View>
  );
}

const styles = {
  container: { width: "100%", alignItems: "center" },
  banner: {
    width: "100%",
    flexDirection: "row",
    marginTop: 10,
    marginBottom: 25,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 30,
    shadowColor: "#000",
    shadowOffset: {
      width: 4,
      height: 8,
    },
    shadowOpacity: 0.75, // Shadow opacity
    shadowRadius: 5, // Shadow blur radius
    elevation: 5, // Elevation for Android
    borderTopWidth: 1,
  },
  page: { width: 1080, height: "100%", alignItems: "center" },
  header: {
    color: "#000",
    fontSize: 50,
    marginBottom: 10,
    fontWeight: "bold",
  },
  subHeader: { color: "#000", fontSize: 25 },
  infoTitle: { color: "#a21", fontSize: 25, marginBottom: 10 },
  infoView: {
    padding: 15,
    width: "30%",
    minHeight: 400,
    margin: 20,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.75, // Shadow opacity
    shadowRadius: 3.84, // Shadow blur radius
    elevation: 5, // Elevation for Android},
  },
};
